@font-face {
  font-family: "Lato";
  src: url("./assets/Lato-Regular.ttf") format("ttf")
}

* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-family: 'Lato', sans-serif;
}

.App {
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}
